/* eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useReducer, useRef } from 'react';

import SocialIcon from './SocialIcon';
import ItemBlock from './ItemBlock';
import { checkEmail } from './api';

import './VerifyEmailForm.scss';
import { reducer, initState } from './state';
import SOCIALS from './socials';

const EMAIL_RE = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
const validateEmail = email =>
  typeof email === 'string' && EMAIL_RE.test(email);

function fetchEmailAPI(state, dispatch) {
  dispatch({ type: 'FETCHING' });
  checkEmail(state.emailValue)
    .then(data => {
      if (data.code && +data.code === 500) {
        dispatch({ type: 'FETCH_ERROR' });
      } else if (data.code && +data.code === 429) {
        dispatch({ type: 'FETCH_ERROR' });
      } else if (data.code && +data.code === 404) {
        dispatch({ type: 'NOT_EXISTS' });
      } else if (data.smtp && !data.smtp.exists) {
        dispatch({ type: 'NOT_EXISTS' });
      } else {
        dispatch({ type: 'SET_FETCH_DATA', payload: data });
      }
    })
    .catch(() => dispatch({ type: 'FETCH_ERROR' }));
}

function linkFromState(state, social) {
  if (state[social])
    return state[social].entry[0].profileUrl || state[social].profile_link;
  if (state.gravatar && state.gravatar.entry[0].accounts) {
    const acc = state.gravatar.entry[0].accounts.find(
      acc => acc.shortname === social
    );
    if (acc) return acc.url;
  }
}

const VerifyEmailForm = () => {
  const inputRef = useRef();
  const [state, dispatch] = useReducer(reducer, initState());

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      if (!validateEmail(state.emailValue)) {
        dispatch({ type: 'INVALID_EMAIL' });
        return;
      }
      fetchEmailAPI(state, dispatch);
      inputRef.current.value = '';
    },
    [state]
  );

  const inputBlurHandler = useCallback(e => {
    dispatch({ type: 'SET_EMAIL', payload: e.target.value });
  });

  const {
    processedEmail,
    fetchData,
    fetchError,
    isEmailValid,
    isFetching,
    emailExists
  } = state;

  const blocksData = fetchData
    ? {
        Exists: fetchData.mxExists ? '+' : '-',
        'Trusted Rate': fetchData.trustRate.toString() + '%',
        SMTP: fetchData.smtpExists ? '+' : '-',
        'Is not SMTP Catch-All': !fetchData.isNotSmtpCatchAll ? '+' : '-',
        'Is not Disposable': !fetchData.isNotDisposable ? '+' : '-'
      }
    : {};

  return (
    <form
      className='email-validate-form'
      id='emailForm'
      onSubmit={handleSubmit}
    >
      <h3 className='email-validate-title'>
        {'Verify Email Address in Real-Time'}
      </h3>
      <div className='email-validate-input'>
        <input
          type='email'
          placeholder='email to verify'
          ref={inputRef}
          onChange={inputBlurHandler}
          required
        />
        {!isEmailValid && <p className='input-error'>{'Fill the email'}</p>}
        <input type='submit' value='Go' />
      </div>

      {isFetching && (
        <div className='spinner'>
          <div />
        </div>
      )}

      {fetchError && (
        <p className='out-of-limit-text'>
          {'your ip reached the limit of requests'}
        </p>
      )}

      {!emailExists && <div>Email does not exist!</div>}

      {!isFetching && processedEmail && (
        <div className='checked-mail-block'>
          <p className='checked-mail-label'>
            {'Checked email: '}
            <span className='checked-mail-result'>{processedEmail}</span>
          </p>
        </div>
      )}

      {fetchData && (
        <div className='results-block'>
          {Object.keys(blocksData).map(name => (
            <ItemBlock key={name} name={name} value={blocksData[name]} />
          ))}
          <div className='results-icons'>
            {Object.keys(SOCIALS).map(name => {
              const href = linkFromState(fetchData, name);
              return (
                <SocialIcon
                  key={name}
                  to={href ? href : '#'}
                  name={name}
                  active={Boolean(href)}
                />
              );
            })}
          </div>
        </div>
      )}
    </form>
  );
};

export default VerifyEmailForm;
