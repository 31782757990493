import React from 'react';
import SEO from './seo';
import PropTypes from 'prop-types';

import RehypeReact from 'rehype-react';
import VerifyEmailForm from './Common/VerifyEmailForm/VerifyEmailForm';

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components: {
    'verify-email-form': VerifyEmailForm
  }
}).Compiler;

const IndexPage = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: { title },
      htmlAst
    }
  } = data;

  return (
    <>
      <SEO
        title={title}
        keywords={[
          'email',
          'check',
          'online',
          'service',
          'mail validation',
          'email verification',
          'bulk api email verification'
        ]}
      />
      {renderAst(htmlAst)}
    </>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object
};

export default IndexPage;
