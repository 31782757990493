import React from 'react';
import PT from 'prop-types';

const SocialIcon = ({ name, active, to }) => (
  <a
    href={to}
    className={`social-link ${name} ${active ? 'active' : ''}`}
    title={name[0].toUpperCase() + name.slice(1)}
  >
    {' '}
  </a>
);

SocialIcon.propTypes = {
  name: PT.string,
  active: PT.bool,
  to: PT.string
};

export default SocialIcon;
