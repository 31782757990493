import React from 'react';
import PropTypes from 'prop-types';
import Index from '../components/index-container';
import { graphql } from 'gatsby';

const RuIndex = ({ data }) => <Index data={data} />;

RuIndex.propTypes = { data: PropTypes.any };

export const query = graphql`
  {
    markdownRemark(frontmatter: { path: { eq: "/" } }) {
      htmlAst
      html
      frontmatter {
        title
        path
      }
    }
  }
`;

export default RuIndex;
