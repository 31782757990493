export const initState = () => ({
  emailValue: '',
  processedEmail: '',
  isEmailValid: true,
  fetchError: false,
  fetchData: null,
  emailExists: true,
  isFetching: false
});

export function reducer(state, { type, payload }) {
  switch (type) {
    case 'SET_EMAIL':
      return {
        ...state,
        emailValue: payload
      };
    case 'SET_FETCH_DATA':
      return {
        ...initState(),
        processedEmail: state.emailValue,
        fetchData: payload
      };
    case 'INVALID_EMAIL':
      return {
        ...state,
        isEmailValid: false
      };
    case 'NOT_EXISTS':
      return {
        ...state,
        isFetching: false,
        emailExists: false
      };
    case 'FETCHING':
      return {
        ...initState(),
        emailValue: state.emailValue,
        processedEmail: state.emailValue,
        isFetching: true
      };
    case 'FETCH_ERROR':
      return {
        ...initState(),
        processedEmail: state.processedEmail,
        fetchError: true
      };
    default:
      return state;
  }
}
